import React, { useCallback } from 'react'
import { useFormikContext } from 'formik'

import { Box, FormRow, FormColumn } from 'containers/cart/styled'
import FormField from 'components/form-field'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import CheckboxField from 'components/form-field/checkbox'
import api from 'common/api'
import debounce from 'lodash/debounce'

type FormErrors = {
  email: string
}

const ContactInformation = () => {
  const { values, errors, handleBlur } = useFormikContext<FormErrors>()

  const handleSubscription = useCallback(
    debounce(async () => {
      if (typeof errors.email === 'undefined' && values.email) {
        await api.subscribe(values.email, { source: 'cart-email-field' })
        gtag('event', 'Cart Email Capture', {
          event_category: 'Lead Capture',
        })
      }
    }, 2000),
    [errors.email, values.email]
  )

  const handleEmailFieldBlur = (e: any) => {
    handleBlur(e) // make sure to call Formik handleBlur to trigger correct status changes for field
    handleSubscription()
  }

  return (
    <Box>
      <h2>Contact information</h2>
      <FormRow>
        <FormColumn>
          <FormField
            id="email-field"
            type="email"
            name="email"
            label="Email Address"
            required
            placeholder="Email Address"
            onBlur={handleEmailFieldBlur}
          />
        </FormColumn>
        <FormColumn>
          <FormField
            id="phone-field"
            type="tel"
            name="phone"
            label="Phone number"
            required
            placeholder="Phone number"
          />
        </FormColumn>
      </FormRow>
      {/* <FormRow>
        <CheckboxField
          name="signup_offers"
          label="Sign me up to receive Fellow offers, promotions and other commercial messages. My request confirms my agreement with Fellow’s Privacy Policy. I can unsubscribe at any time."
        />
      </FormRow> */}
    </Box>
  )
}

export default ContactInformation
