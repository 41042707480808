import React, { FC } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { useCartContext } from 'context/cart'
import Img from 'gatsby-image'
import {
  Table,
  THead,
  TBodySecondary,
  PromoCodeTitle,
  FieldsWrap,
  QuantityButton,
  QuantityButtonsWrap,
  PriceTableColumn,
  TBody,
  ItemTitle,
  ItemDescription,
  ImageColumn,
  ImageWrap,
  Total,
  Price,
  PromoCodeTableColumn,
  CrossedOut,
  SecondaryTable,
  Tooltip,
  PriceTableAdditionalCharge,
  SecondaryTableTotal,
  SecondaryTableAdditionalCharge,
  QuantityTableColumn,
  MobileQuantityPriceRow,
  PriceTableRow,
  ItemColumnHeading,
  MobilePriceHeading,
  MobilePriceColumn,
  MobileItemDescription,
} from './styled'
import MinusCircle from 'components/icons/minus-circle'
import PlusCircle from 'components/icons/plus-circle'
import PromoCodeField from 'components/form-field/promo-code'
import { formatCurrency } from 'utils/format'
import useTotalPrice from 'hooks/use-total-price'

type QueryResult = {
  allStripeSku: AllStripeSkuType
  kitImage: FluidImage
  kitPlusCryoImage: FluidImage
  vasectomyKitImage: FluidImage
}

interface SkuInfo {
  [key: string]: IndividualSku
}

interface IndividualSku {
  description: string
  mobileDescription?: string
  image: FluidImage
  additionalCharge?: string
}

const query = graphql`
  query {
    allStripeSku(sort: { fields: created, order: ASC }) {
      edges {
        node {
          id
          price
          attributes {
            name
          }
          product {
            name
          }
          created
        }
      }
    }
    kitImage: file(relativePath: { eq: "kit-top-view.png" }) {
      childImageSharp {
        fluid(maxWidth: 170, webpQuality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    kitPlusCryoImage: file(relativePath: { eq: "kit-plus-cryo-top-view.png" }) {
      childImageSharp {
        fluid(maxWidth: 200, webpQuality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    vasectomyKitImage: file(relativePath: { eq: "vasectomy-kit-top-view.png" }) {
      childImageSharp {
        fluid(maxWidth: 200, webpQuality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const CartTable: FC = () => {
  const { getCount, items, updateItems, promoCode } = useCartContext()
  const {
    allStripeSku,
    kitImage,
    kitPlusCryoImage,
    vasectomyKitImage,
  } = useStaticQuery<
    QueryResult
  >(query)
  const { totalOrderPrice, discount } = useTotalPrice()
  const cryoKitSku = allStripeSku.edges[1]
  const cryoKitCartItemQuantity = items[cryoKitSku.node.id]
  const hasCryoKit = cryoKitCartItemQuantity > 0
  const totalSubscriptionPrice = cryoKitCartItemQuantity * 140
  const skuInfo: SkuInfo = {
    // These keys MUST match the Name of the SKUs in Stripe
    'Semen Analysis': {
      description: 'The easiest and most trusted mail-in semen analysis.',
      image: kitImage,
    },
    'Semen Analysis + Cryopreservation': {
      description: `Mail-in semen analysis + 3 vials stored for $140/year.`,
      image: kitPlusCryoImage,
      additionalCharge: `$${totalSubscriptionPrice}/year`,
    },
    'Vasectomy Test': {
      description: 'Trusted, fast, and convenient way to test the success of your vasectomy.',
      image: vasectomyKitImage,
    },
  }
  return (
    <>
      <Table>
        <THead>
          <tr>
            <th aria-label="Product image" />
            <ItemColumnHeading>
              <div>
                <span>Item</span>
                <MobilePriceHeading>Price</MobilePriceHeading>
              </div>
            </ItemColumnHeading>
            <th>Quantity</th>
            <th>Price</th>
          </tr>
        </THead>
        <TBody>
          {allStripeSku.edges.map(node => {
            const itemQuantity = items[node.node.id]
            return (
              itemQuantity > 0 && (
                <PriceTableRow key={`cart-item-${node.node.id}`}>
                  <ImageColumn>
                    <ImageWrap>
                      <Img
                        fluid={
                          skuInfo[node.node.attributes.name]['image']
                            .childImageSharp.fluid
                        }
                        alt=""
                      />
                    </ImageWrap>
                  </ImageColumn>
                  <td>
                    <ItemTitle>{node.node.attributes.name}</ItemTitle>
                    <ItemDescription
                      itemHasMobileDescription={Boolean(
                        skuInfo[node.node.attributes.name]['mobileDescription']
                      )}
                      dangerouslySetInnerHTML={{
                        __html:
                          skuInfo[node.node.attributes.name]['description'],
                      }}
                    ></ItemDescription>
                    {Boolean(
                      skuInfo[node.node.attributes.name]['mobileDescription']
                    ) ? (
                      <MobileItemDescription
                        dangerouslySetInnerHTML={{
                          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                          __html: skuInfo[node.node.attributes.name][
                            'mobileDescription'
                          ]!!,
                        }}
                      ></MobileItemDescription>
                    ) : null}
                    <MobileQuantityPriceRow>
                      <td>
                        <QuantityButtonsWrap>
                          <QuantityButton
                            onClick={() => {
                              if (itemQuantity > 0) {
                                updateItems({[node.node.id]: -1})
                              }
                            }}
                            type="button"
                          >
                            <MinusCircle />
                          </QuantityButton>
                          {itemQuantity}
                          <QuantityButton
                            onClick={() => {
                              if (itemQuantity < 10) {
                                updateItems({[node.node.id]: 1})
                              }
                            }}
                            plus
                            type="button"
                          >
                            <PlusCircle />
                          </QuantityButton>
                        </QuantityButtonsWrap>
                      </td>
                      <MobilePriceColumn>
                        {itemQuantity
                          ? `${formatCurrency(node.node.price * itemQuantity)}`
                          : `${formatCurrency(0)}`}
                        {skuInfo[node.node.attributes.name][
                          'additionalCharge'
                        ] ? (
                          <PriceTableAdditionalCharge
                            show={Boolean(itemQuantity)}
                          >
                            +{' '}
                            {
                              skuInfo[node.node.attributes.name][
                                'additionalCharge'
                              ]
                            }
                          </PriceTableAdditionalCharge>
                        ) : null}
                      </MobilePriceColumn>
                    </MobileQuantityPriceRow>
                  </td>
                  <QuantityTableColumn>
                    <QuantityButtonsWrap>
                      <QuantityButton
                        onClick={() => {
                          if (itemQuantity > 0) {
                            updateItems({[node.node.id]: -1})
                          }
                        }}
                        type="button"
                      >
                        <MinusCircle />
                      </QuantityButton>
                      {itemQuantity}
                      <QuantityButton
                        onClick={() => {
                          if (itemQuantity < 10) {
                            updateItems({[node.node.id]: 1})
                          }
                        }}
                        plus
                        type="button"
                      >
                        <PlusCircle />
                      </QuantityButton>
                    </QuantityButtonsWrap>
                  </QuantityTableColumn>
                  <PriceTableColumn
                    cryoRow={Boolean(
                      skuInfo[node.node.attributes.name]['additionalCharge']
                    )}
                  >
                    {itemQuantity
                      ? `${formatCurrency(node.node.price * itemQuantity)}`
                      : `${formatCurrency(0)}`}
                    {skuInfo[node.node.attributes.name]['additionalCharge'] ? (
                      <PriceTableAdditionalCharge
                        show={Boolean(itemQuantity)}
                      >
                        +{' '}
                        {skuInfo[node.node.attributes.name]['additionalCharge']}
                      </PriceTableAdditionalCharge>
                    ) : null}
                  </PriceTableColumn>
                </PriceTableRow>
              )
            )
          })}
        </TBody>
      </Table>
      <SecondaryTable>
        <TBodySecondary>
          <tr>
            <td>Doctor approved results</td>
            <td>Included</td>
          </tr>
          <tr>
            <td>{getCount() === 1 ? 'Expedited Shipping' : 'UPS Ground Shipping' }</td>
            <td>
              <CrossedOut>{getCount() === 1 ? '$30' : '$20' }</CrossedOut>
              Free
            </td>
          </tr>
          <tr>
            <PromoCodeTableColumn>
              <PromoCodeTitle>Have a referral code?</PromoCodeTitle>
              <FieldsWrap>
                <PromoCodeField
                  name="promoCode"
                  label=""
                  placeholder="Referral Code"
                />
              </FieldsWrap>
            </PromoCodeTableColumn>
            {discount !== 0 && promoCode && promoCode.percent_off && (
              <td>
                {promoCode.percent_off}% (-${formatCurrency(discount)})
              </td>
            )}
            {discount !== 0 && promoCode && promoCode.amount_off && (
              <td>-${formatCurrency(discount)}</td>
            )}
          </tr>
          <SecondaryTableTotal hasCryoKit={hasCryoKit}>
            <td>
              <Total>Total {hasCryoKit ? 'payment today' : ''}</Total>
            </td>
            <td>
              <Price>${formatCurrency(totalOrderPrice)}</Price>
            </td>
          </SecondaryTableTotal>
          {hasCryoKit ? (
            <SecondaryTableAdditionalCharge>
              <td>
                Annual storage fee - billed when your sample is approved and
                stored.
              </td>
              <td>{`$${totalSubscriptionPrice}/year`}</td>
            </SecondaryTableAdditionalCharge>
          ) : null}
        </TBodySecondary>
      </SecondaryTable>
    </>
  )
}

export default CartTable
