import styled, { css } from 'styled-components'
import mq from 'styles/mq'
import { font, paragraph } from 'styles/typography'
import ExclamationMark from 'components/icons/exclamation-mark'
import theme from 'styles/theme'

export const Wrap = styled.div`
  width: 100%;
`

export const InputWrap = styled.div<{
  error?: boolean
  disabled?: boolean
  success?: boolean
}>`
  border-bottom: 0.3rem solid;
  display: flex;
  align-items: center;
  padding-bottom: 0.6rem;
  flex: 1;

  border-color: ${({ error, success }) =>
    error
      ? theme.color.error
      : success
      ? theme.color.background.dark
      : theme.color.accent.primary};

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.3;
    `}
`

export const CardInputWrap = styled(InputWrap as any)`
  padding-bottom: 1.2rem;
`

export const Input = styled.input`
  border: 0;
  background: none;
  display: inline-block;
  ${paragraph.small};
  font-family: ${font.base};
  font-weight: 300;
  color: #172126;
  flex: 1;
  padding: 0.6rem 0;

  &::placeholder {
    font-weight: 300;
    ${paragraph.small};
    color: ${theme.color.background.dark};
    opacity: 0.6;
  }
`

export const Label = styled.label<{ disabled?: boolean }>`
  font-family: ${font.headings};
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 2rem;
  color: ${({ disabled }) => (disabled ? 'rgba(23, 33, 38, 0.3)' : '#172126')};
  display: block;
  margin-bottom: 0.8rem;
`

export const ValidationMessage = styled.span`
  font-family: ${font.base};
  ${paragraph.extraSmall};
  color: #d81100;
`

export const ErrorIcon = styled(ExclamationMark)`
  color: ${theme.color.error};
  width: 1.4rem;
  height: 1.4rem;

  ${mq.medium} {
    width: 1.4rem;
    height: 1.4rem;
  }
`

export const CardWrap = styled(Wrap as any)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .StripeElement {
    width: 100%;
  }
`

export const CardFieldColumn = styled.div`
  flex: 1;

  &:not(:last-child) {
    margin-right: 2.4rem;
    margin-bottom: 3.3rem;
  }

  &:first-child {
    ${mq.medium} {
      margin-bottom: 2.4rem;
    }
  }

  ${mq.medium} {
    margin-bottom: 0;
  }
`

export const CardNumberWrap = styled(CardFieldColumn as any)`
  width: 100%;
  flex: unset;

  ${mq.medium} {
    width: 50%;
    margin-right: 2.4rem;
  }
`

export const cardOptions = {
  style: {
    base: {
      iconColor: theme.color.accent.primary,
      color: theme.color.text.dark,
      fontFamily: font.base,
      fontWeight: '300',
      fontSize: '17px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': { color: theme.color.background.dark },
      '::placeholder': { color: theme.color.background.dark },
    },
    invalid: {
      iconColor: theme.color.error,
      color: theme.color.error,
    },
  },
}
