import React, { FC } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { LinkStyled } from 'containers/cart/styled'
import { Wrap, Title, ListItem } from './styled'
import urls from 'common/urls'
import { useCartContext } from 'context/cart'

type QueryResult = {
  allStripeSku: AllStripeSkuType
}

const query = graphql`
  query {
    allStripeSku(sort: { fields: created, order: ASC }) {
      edges {
        node {
          id
          price
          attributes {
            name
          }
          product {
            name
          }
          created
        }
      }
    }
  }
`
const OrderDetails = () => {
  const { items } = useCartContext()
  const { allStripeSku } = useStaticQuery<QueryResult>(query)
  const cryoKitSku = allStripeSku.edges[1]
  const cryoKitCartItemQuantity = items[cryoKitSku.node.id]
  const hasCryoKit = cryoKitCartItemQuantity > 0
  return (
    <Wrap>
      <Title>Order details</Title>
      <ul>
        <ListItem>
          This product is available in all 50 states except New York,
          Hawaii, and Alaska.
        </ListItem>
        <ListItem>
          Planning for your kit: You will need to abstain from ejaculating for
          48 hours (but no more than 5 days) before generating your sample for
          your<span translate="no"> Fellow </span>test. You can also only do your test Monday-Thursday
          morning.
        </ListItem>
        <ListItem>
          Once you receive your kit, follow the directions to provide your
          sample and send your kit back to our lab for analysis. You’ll get your
          results emailed to you as soon as your analysis is complete.
        </ListItem>
        {hasCryoKit && (
          <ListItem>
            You will not be charged the $140/year price for cryopreservation
            until we successfully bank your sample. If we do not detect any
            moving sperm cells, we will not cryopreserve your sample and you
            will not be charged for storage. Your results will inform you of if
            we successfully cryopreserved your sample and how many cells were
            stored. The price of your kit will not be refunded if we can not
            cryopreserve your sample. Storage fees are billed on an annual basis
            and there will not be pro-rated refunds issued for cancellation of
            the service during the middle of the payment cycle.
          </ListItem>
        )}
        <ListItem>
          Intended for sale and use in the US only. The resale of our kits is
          prohibited, and<span translate="no"> Fellow </span>can only guarantee services and provide support
          for kits purchased directly through our website.
        </ListItem>
      </ul>
      <p>
        Learn more at our{' '}
        <LinkStyled href={urls.faq} target="_blank">
          FAQs page
        </LinkStyled>
        {/* or{' '}<LinkStyled to="/">FSA/HSA</LinkStyled> eligibility. */}
      </p>
    </Wrap>
  )
}

export default OrderDetails
