import React, { FC, ReactNode } from 'react'
import { useField } from 'formik'

import { ValidationMessage, Wrap } from 'components/form-field/styled'
import {
  CheckboxIcon,
  RadioIcon,
  CheckboxInput,
  Label,
  IconWrap,
} from './styled'

type Props = {
  label: string | ReactNode
  disabled?: boolean
  name: string
  type?: 'checkbox' | 'radio',
  onChange?: (e: any) => void,
  radioGroupValue?: string,
  value ?: string
}

const CheckboxField: FC<Props> = ({ label, ...props }) => {
  const type = props.type || 'checkbox'
  const [field, meta] = useField(props)
  const hasError = meta.touched && !!meta.error

  return (
    <Wrap>
      <Label disabled={props.disabled}>
        <IconWrap>
          <CheckboxInput type={type} {...field} {...props} />
          {type === 'radio' && (field.value === props.radioGroupValue)  && <RadioIcon checked/>}
          {type === 'radio' && (field.value !== props.radioGroupValue)  && <RadioIcon />}
          {type === 'checkbox' && <CheckboxIcon checked={field.value} />}
        </IconWrap>
        {label}
      </Label>
      {hasError && <ValidationMessage>{meta.error}</ValidationMessage>}
    </Wrap>
  )
}

export default CheckboxField