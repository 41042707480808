import React, { FC, ReactNode } from 'react'
import { useField } from 'formik'

import Tooltip from 'components/tooltip'
import {
  Wrap,
  InputWrap,
  ValidationMessage,
  Label,
  ErrorIcon,
  SuccessIcon,
  InputGroup,
} from './styled'

type Props = FormField & {
  Component: FC
  afterInput?: string | ReactNode
}

const FieldWrap: FC<Props> = ({
  disclaimer,
  label,
  required,
  Component,
  afterInput,
  ...props
}) => {
  const [field, meta] = useField(props)
  const hasError = (meta.touched && meta.error) as boolean
  const hasSuccess = !meta.error && meta.value

  return (
    <Wrap>
      <Label htmlFor={field.name}>
        {label}
        <sup>{required && <>*</>}</sup>
      </Label>
      <InputGroup>
        <InputWrap error={hasError} success={hasSuccess}>
          <Component {...field} {...props} />
          {hasError && <ErrorIcon />}
          {hasSuccess && <SuccessIcon />}
          {disclaimer && <Tooltip>{disclaimer}</Tooltip>}
        </InputWrap>
        {afterInput}
      </InputGroup>
      {hasError && <ValidationMessage>{meta.error}</ValidationMessage>}
    </Wrap>
  )
}

export default FieldWrap
