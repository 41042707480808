import React, { FC } from 'react'

import {
  Input,
} from './styled'
import FieldWrap from './field-wrap'

const FormField: FC<FormField> = props => <FieldWrap Component={Input} {...props} />

export default FormField;
