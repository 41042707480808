import React from 'react'
import { useFormikContext } from 'formik'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Box, FormRow, FormColumn } from 'containers/cart/styled'
import CardDetails from 'components/form-field/card-details'
import CheckboxField from 'components/form-field/checkbox'
import AddressPreview from 'containers/cart/address-preview/address-preview'
import AddressForm from 'containers/cart/address-form'

type QueryResult = {
  cardsImage: FluidImage
}

const query = graphql`
  query {
    cardsImage: file(relativePath: { eq: "cards.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 212, webpQuality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const BillingInformation = () => {
  const {
    values: { same_billing_address, shipping },
  } = useFormikContext()
  const { cardsImage } = useStaticQuery<QueryResult>(query)

  return (
    <Box>
      <h2>Billing information</h2>
      <FormRow>
        <CardDetails />
      </FormRow>
      <FormRow>
        <FormColumn>
          <div
            style={{
              width: '21.2rem',
            }}
          >
            <Img fluid={cardsImage.childImageSharp.fluid} alt="" />
          </div>
        </FormColumn>
      </FormRow>
      <FormRow>
        <FormColumn>
          <CheckboxField
            name="same_billing_address"
            label="Billing address is the same as shipping address"
          />
        </FormColumn>
      </FormRow>
      {same_billing_address && <AddressPreview shipping={shipping} />}
      {!same_billing_address && <AddressForm fieldsPrefix="billing" />}
    </Box>
  )
}

export default BillingInformation
