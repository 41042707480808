import React, { FC, useState, useEffect } from 'react'
import { useFormikContext } from 'formik'
import FieldWrap from '../field-wrap'
import { Input } from '../styled'
import { useCartContext } from 'context/cart'
import api from 'common/api'

type FormValues = {
  promoCode: string
}

const PromoCodeField: FC<FormField> = props => {
  const [, setValidating] = useState(false)
  const { applyPromoCode, removePromoCode, items } = useCartContext()
  const { values, touched, validateField } = useFormikContext<FormValues>()
  const skusInCart: string[] = []
  for (const [sku, count] of Object.entries(items)) {
    for (let i = 0; i < count; i++) {
      skusInCart.push(sku)
    }
  }

  const validatePromoCode = async (value: string) => {
    if (value) {
      setValidating(true)
      try {
        const promoCode = await api.postPromoCode(
          value,
          skusInCart.join()
        )
        applyPromoCode(promoCode)
        if (!(promoCode.amount_off || promoCode.percent_off)) {
          return 'This is not a valid referral code.'
        }
      } catch (e) {
        removePromoCode()
        return e.message || 'Something went wrong, please try again.'
      } finally {
        setValidating(false)
      }
    } else {
      removePromoCode()
    }

    return ''
  }

  // Trigger initial validation if promoCode has a value before being touched
  // to handle the pre-filled from qparams case
  useEffect(() => {
    if (typeof touched.promoCode === 'undefined' && values.promoCode !== '')
      validateField('promoCode')
  }, [values.promoCode, touched.promoCode, validateField])

  useEffect(() => {
    validateField('promoCode')
  }, [items, validateField])

  return <FieldWrap {...props} Component={Input} validate={validatePromoCode} />
}

export default PromoCodeField
