import styled, { css } from 'styled-components'
import QuestionMark from 'components/icons/question-mark'
import { paragraph } from 'styles/typography'
import theme from 'styles/theme'

export const Container = styled.div<{ absolute?: boolean }>`
  display: inline-block;
  position: relative;
  vertical-align: middle;

  ${props =>
    props.absolute &&
    css`
      position: static;
    `}
`

export const Button = styled.button<{ absolute?: boolean }>`
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
  appearance: none;
  background: none;

  ${props =>
    props.absolute &&
    css`
      position: absolute;
      top: 50%;
      right: 0;
      margin-top: -1.3rem;
    `}
`

export const Icon = styled(QuestionMark)`
  color: #c6d3de;
  fill: none;
  width: 2.6rem;
  height: 2.6rem;
`

export const Overlay = styled.button`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  z-index: 950;
`

export const Content = styled.div`
  ${paragraph.small}
  display: flex;
  justify-content: flex-end;
  font-size: 1.4rem;
  font-weight: normal;
  position: absolute;
  bottom: 100%;
  right: -0.6rem;
  width: 24em;
  max-width: calc(100vw - 6rem);
  z-index: 1000;
`

export const Wrapper = styled.div`
  position: relative;
  margin-bottom: 2rem;
  padding: 2.4rem;
  filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.1));
  background: white;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 100%;
    right: 1rem;
    border: 1rem solid transparent;
    border-bottom-width: 0;
    border-top: 1.4rem solid white;
  }
`

export const CloseButton = styled(Button as any)`
  position: absolute;
  display: block;
  width: 1.4rem;
  height: 1.4rem;
  top: 1rem;
  right: 1rem;

  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 0;
    height: 100%;
    margin: 0 -1px;
    transform: rotateZ(45deg);
    border-left: 2px solid ${theme.color.text.dark};
  }

  &:after {
    transform: rotateZ(-45deg);
  }
`
