import React, { FC, SVGProps } from 'react'
import Svg from 'components/svg'

const ArrowDown: FC<SVGProps<SVGSVGElement>> = props => (
  <Svg {...props} viewbox="0 0 18 12" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.25404 7.23108L15.4392 0.947754L17.5771 3.05231L9.25404 11.5075L0.930937 3.05231L3.06889 0.947754L9.25404 7.23108Z"
      fill="#172126"
    />
  </Svg>
)

export default ArrowDown
