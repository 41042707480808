import React from 'react'

import Layout from 'components/layout'
import Cart from 'containers/cart/Cart'

const CartPage = () => {
  return (
    <Layout>
      <Cart />
    </Layout>
  )
}

export default CartPage
