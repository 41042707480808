import React, { FC, SVGProps } from 'react'
import Svg from 'components/svg'

const ExclamationMark: FC<SVGProps<SVGSVGElement>> = props => (
  <Svg {...props} viewbox="0 0 14 14">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14Z"
      fill="currentColor"
    />
    <path
      d="M7 7.71884L7 3.99999"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="square"
    />
    <path d="M7 11.8126V9.81256" stroke="white" strokeWidth="2" />
  </Svg>
)

export default ExclamationMark
