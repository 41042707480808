import styled from 'styled-components'
import { heading } from 'styles/typography'
import theme from 'styles/theme'

export const Wrap = styled.section`
  margin: 3.1rem 0;
  padding: 0 4.1rem;
  font-size: 1.4rem;
  line-height: 2.2rem;
  color: ${theme.color.background.dark};
`

export const Title = styled.h2`
  ${heading.s};
`

export const ListItem = styled.li`
  margin-bottom: 1.8rem;

  &:before {
    content: '•';
    display: inline-block;
    margin-right: 0.5rem;
  }
`
