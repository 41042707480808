import React, { FC } from 'react'
import {
  CardWrap,
  CardNumberWrap,
  CardFieldColumn,
} from './styled'
import CardNumber from 'components/card-elements/card-number'
import CardExpiration from 'components/card-elements/card-expiration'
import CardCvc from 'components/card-elements/card-cvc'

const CardDetails: FC= () => (
  <CardWrap>
    <CardNumberWrap>
      <CardNumber name="card_number" />
    </CardNumberWrap>
    <CardFieldColumn>
      <CardExpiration name="card_expiration" />
    </CardFieldColumn>
    <CardFieldColumn>
      <CardCvc name="card_cvc" />
    </CardFieldColumn>
  </CardWrap>
)

export default CardDetails
