import React, { FC } from 'react'
import { CardExpiryElement } from '@stripe/react-stripe-js'
import {
  CardInputWrap,
  ErrorIcon,
  Label,
  ValidationMessage,
} from 'components/form-field/card-details/styled'
import useCardElementsHelpers from 'hooks/use-card-elements-helpers'
import { SuccessIcon } from 'components/form-field/styled'

const CardExpiration: FC<Pick<HTMLInputElement, 'name'>> = props => {
  const { hasError, hasSuccess, error, field } = useCardElementsHelpers(props)

  return (
    <div>
      <Label htmlFor={props.name}>
        Expiration date <sup>*</sup>
      </Label>
      <CardInputWrap error={hasError} success={hasSuccess}>
        <CardExpiryElement {...field} />
        {hasError && <ErrorIcon />}
        {hasSuccess && <SuccessIcon />}
      </CardInputWrap>
      {hasError && <ValidationMessage>{error}</ValidationMessage>}
    </div>
  )
}

export default CardExpiration
