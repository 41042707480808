import styled from 'styled-components'
import mq from 'styles/mq'

import ReactModalWrapper from 'components/modal'

//Quirk with react-modal and using styled-components (the attrs(...))
//https://github.com/reactjs/react-modal/issues/603

export const StyledExpeditedShippingModal = styled(ReactModalWrapper).attrs({
  overlayClassName: 'Overlay',
  modalClassName: 'Modal',
})`
  .Modal {
    text-align: left;
    position: absolute;
    margin: auto;
    overflow: auto;
    outline: none;
  }

  .Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }

  ${mq.extraSmall} {
    .Modal {
      font-size: 0.7em;
      top: 5em;
      left: 2rem;
      right: 2rem;
      bottom: 2rem;
      width: 27rem;
      height: 50rem;
    }
  }

  ${mq.small} {
    .Modal {
      font-size: 0.8em;
      top: 5em;
      width: 32rem;
      height: 59rem;
    }
  }

  ${mq.medium} {
    .Modal {
      font-size: 1em;
      top: 10rem;
      left: 2rem;
      right: 2rem;
      bottom: 2rem;
      width: 55rem;
      min-height: 42rem;
    }
  }
`

export const ButtonWrap = styled.div`
  text-align: center;
  margin: 3rem 0 0;
`