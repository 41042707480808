import React, { useState, FC } from 'react'

import {
  Container,
  Button,
  Icon,
  Content,
  Overlay,
  CloseButton,
  Wrapper,
} from './styled'

const Tooltip: FC<{ absolute?: boolean }> = ({ children, absolute }) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleOpen = () => setIsOpen(true)
  const handleClose = () => setIsOpen(false)

  return (
    <Container absolute={absolute}>
      {isOpen && (
        <>
          <Overlay onClick={handleClose} />
          <Content>
            <Wrapper>
              <CloseButton onClick={handleClose} />
              {children}
            </Wrapper>
          </Content>
        </>
      )}

      <Button type="button" onClick={handleOpen} absolute={absolute}>
        <Icon />
      </Button>
    </Container>
  )
}

export default Tooltip
