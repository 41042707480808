import styled, { css } from 'styled-components'

import mq from 'styles/mq'
import { font, paragraph } from 'styles/typography'
import theme from 'styles/theme'
import ExclamationMark from 'components/icons/exclamation-mark'
import Check3 from 'components/icons/check-3'

export const Wrap = styled.div`
  width: 100%;
`

export const InputWrap = styled.div<{
  error?: boolean
  disabled?: boolean
  success?: boolean
}>`
  border-bottom: 0.3rem solid;
  border-color: ${({ error, success }) =>
    error
      ? theme.color.error
      : success
      ? theme.color.background.dark
      : theme.color.accent.primary};
  display: flex;
  align-items: center;
  padding-bottom: 0.6rem;
  flex: 1;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.3;
    `}

  input,
  textarea {
    &:focus::placeholder {
      color: transparent;
    }
  }
`

export const SelectFieldInputWrap = styled(InputWrap as any)<{
  opened?: boolean
  hasSuccess?: boolean
}>`
  padding-bottom: 0.4rem;

  ${({ opened, hasSuccess }) =>
    (opened || hasSuccess) &&
    css`
      border-color: ${theme.color.background.dark};
    `}
`

export const InputGroup = styled.div`
  display: flex;
`

export const Input = styled.input`
  border: 0;
  background: none;
  display: inline-block;
  ${paragraph.small};
  font-family: ${font.base};
  font-weight: 300;
  color: ${theme.color.background.dark};
  flex: 1;
  padding: 0.6rem 0;

  &::placeholder {
    font-weight: 300;
    ${paragraph.small};
    color: ${theme.color.background.dark};
    opacity: 0.6;
  }
`

export const Label = styled.label<{ disabled?: boolean }>`
  display: block;
  position: relative;
  font-family: ${font.headings};
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 2rem;
  color: ${({ disabled }) =>
    disabled ? 'rgba(23, 33, 38, 0.3)' : theme.color.background.dark};
  margin-bottom: 0.8rem;
`

export const ValidationMessage = styled.span`
  font-family: ${font.base};
  ${paragraph.extraSmall};
  color: #d81100;
`

export const ErrorIcon = styled(ExclamationMark)`
  color: ${theme.color.error};
  width: 1.4rem;
  height: 1.4rem;

  ${mq.medium} {
    width: 1.4rem;
    height: 1.4rem;
  }
`

export const SuccessIcon = styled(Check3)`
  color: ${theme.color.success};
  width: 1.4rem;
  height: 1.4rem;

  ${mq.medium} {
    width: 1.4rem;
    height: 1.4rem;
  }
`
