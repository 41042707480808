import styled, { css } from 'styled-components'
import ReactTooltip from 'react-tooltip'
import theme from 'styles/theme'
import { font, heading, paragraph } from 'styles/typography'
import mq from 'styles/mq'

import ReactModalWrapper from 'components/modal'

export const Table = styled.table`
  width: 100%;
  border-spacing: 0;
  margin: 0;
  font-family: ${font.headings};

  thead,
  tbody {
    td,
    th {
      &:nth-child(2) {
        text-align: left;
      }

      &:nth-child(3) {
        text-align: center;
        display: none;

        ${mq.medium} {
          display: table-cell;
        }
      }
    }

    th:last-child {
      text-align: right;
      display: none;

      ${mq.medium} {
        display: table-cell;
      }
    }
  }
`

export const TBody = styled.tbody`
  td {
    ${heading.xs};
    padding: 1.6rem 0;

    ${mq.medium} {
      padding: 0;
    }
  }
`

export const THead = styled.thead`
  th {
    text-transform: uppercase;
    ${heading.xxs};
    color: ${theme.color.background.dark};
    padding-bottom: 1.9rem;
    border-bottom: 1px solid ${theme.color.border.blue};
  }
`

export const SecondaryTable = styled.table`
  width: 100%;
  border-spacing: 0;
  margin-top: 2.4rem;
`

export const TBodySecondary = styled.tbody`
  font-family: ${font.base};

  td {
    padding-top: 0;
    padding-bottom: 0.4rem;
    font-size: 1.4rem;
    line-height: 2.2rem;

    &:last-child {
      text-align: right;
    }

    ${mq.medium} {
      padding-bottom: 1.6rem;
    }
  }
`

export const PromoCodeTitle = styled.h3`
  margin-bottom: 0;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
`

export const FieldsWrap = styled.div`
  display: flex;

  ${mq.medium} {
    flex-direction: row;
    width: 30rem;
  }
`

export const PriceTableRow = styled.tr`
  td {
    border-bottom: 1px solid ${theme.color.border.blue};
    padding-top: 4rem;
    padding-bottom: 3rem;
  }

  &:nth-of-type(2) {
    td {
      min-height: 12rem;
    }
  }
`

export const PromoCodeTableColumn = styled.td`
  text-align: left !important;
  padding-top: 1.6rem !important;
  padding-bottom: 0;

  div {
    max-width: 26.4rem;

    ${mq.small} {
      width: 26.4rem;
    }
  }

  ${mq.medium} {
    padding-top: 2.4rem !important;
  }
`

export const PriceTableColumn = styled.td<{ cryoRow?: boolean }>`
  display: none;
  text-align: right;
  ${heading.xs};
  min-width: 8rem;

  ${mq.medium} {
    display: table-cell;
  }

  ${({ cryoRow }) =>
  cryoRow &&
  css`
    padding-top: 9rem !important;
  `}
`
export const MobilePriceColumn = styled.td`
  text-align: right !important;
`

export const QuantityButtonsWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

export const QuantityButton = styled.button<{ plus?: boolean }>`
  background: none;
  border: none;
  cursor: pointer;

  svg {
    width: 2.4rem;
    height: 2.4rem;

    ${({ plus }) =>
      plus &&
      css`
        color: ${theme.color.accent.primary};
      `}
  }
`

export const ItemTitle = styled.h3`
  font: bold 2rem/2.4rem ${font.headings};
  letter-spacing: -0.064rem;
  margin-bottom: 0;
`

export const ItemDescription = styled.p<{ itemHasMobileDescription?: boolean }>`
  ${paragraph.extraSmall};
  font-weight: 300;
  max-width: 23rem;
  color: ${theme.color.background.dark};
  font-family: ${font.base};

  ${({ itemHasMobileDescription }) =>
  itemHasMobileDescription &&
  css`
    display: none;

    ${mq.desktop} {
      display: inherit;
    }
  `}
`

export const MobileItemDescription = styled.p`
  ${paragraph.extraSmall};
  font-weight: 300;
  max-width: 23rem;
  color: ${theme.color.background.dark};
  font-family: ${font.base};

  ${mq.desktop} {
    display: none;
  }
`

export const ImageWrap = styled.figure`
  margin: 0 2rem 0 0;
  width: 10rem;

  ${mq.medium} {
    width: 17rem;
    height: 14rem;
  }

  overflow: hidden;
`

export const Total = styled.h3`
  ${heading.s};
  margin: 1.6rem 0 0 0;
  padding-bottom: 0;

  ${mq.medium} {
    margin-top: 4.557rem;
  }
`

export const Price = styled(Total as any)`
  letter-spacing: -0.36px;
  padding: 1rem 0;
`

export const Title = styled.h2`
  display: inline-block;
  margin-bottom: 0;
`

export const CrossedOut = styled.span`
  text-decoration: line-through;
  display: inline-block;
  margin-right: 0.5rem;
`

export const Tooltip = styled(ReactTooltip)`
  max-width: 40rem;
  filter: drop-shadow(0 0.1rem 0.1rem ${theme.color.text.lightGrey});
  font-family: ${font.base};
  font-weight: 300;
  letter-spacing: 0.03rem;
  opacity: 100% !important;
`

export const PriceTableAdditionalCharge = styled.p<{ show?: boolean }>`
  font-family: ${font.base};
  font-weight: 300;
  font-size: 1.4rem;
  display: none;
  visibility: hidden;
  margin-top: 0.5rem;

  ${mq.medium} {
    display: block;
    margin-top: 1.4rem;
  }

  ${({ show }) =>
  show &&
  css`
    display: block;
    visibility: visible;
  `}
`

export const SecondaryTableTotal = styled.tr<{ hasCryoKit?: boolean }>`
  ${({ hasCryoKit }) =>
  hasCryoKit &&
  css`
    td {
      padding-bottom: 0;
    }
  `}
`

export const SecondaryTableAdditionalCharge = styled.tr`
  td {
    padding-bottom: 5rem;

    &:last-child {
      ${heading.s};
      font-weight: 700;
      font-size: 1.5rem;
    }
  }
`

export const QuantityTableColumn = styled.td`
  display: none;

  ${mq.medium} {
    display: table-cell;
  }
`

export const MobileQuantityPriceRow = styled.tr`
  display: flex;
  justify-content: space-between;
  align-items: center;

  td {
    border-bottom: none !important;
  }

  ${mq.medium} {
    display: none;
  }
`
export const ItemColumnHeading = styled.th`
  div {
    display: flex;
    justify-content: space-between;
  }
`

export const MobilePriceHeading = styled.span`
  display: inherit;

  ${mq.medium} {
    display: none;
  }
`

export const ImageColumn = styled.td`
  padding: 3rem 0 0 !important;
  vertical-align: top;
`

export const MobileModal = styled(ReactModalWrapper).attrs({
  overlayClassName: 'Overlay',
  modalClassName: 'Modal',
})`
  .Modal {
    text-align: left;
    position: absolute;
    margin: auto;
    overflow: auto;
    outline: none;
  }

  .Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }

  ${mq.extraSmall} {
    .Modal {
      font-size: 0.7em;
      top: 5em;
      left: 2rem;
      right: 2rem;
      bottom: 2rem;
      width: 27rem;
      height: 50rem;
    }
  }

  ${mq.small} {
    .Modal {
      font-size: 0.8em;
      top: 5em;
      width: 32rem;
      height: 59rem;
    }
  }
`

export const ButtonWrap = styled.div`
  text-align: center;
  margin: 3rem 0 0;
`
