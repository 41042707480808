import React, { FC, SVGProps } from 'react'
import Svg from 'components/svg'

const AmazonPay: FC<SVGProps<SVGSVGElement>> = props => (
  <Svg {...props} viewbox="0 0 83 16">
    <g clipPath="url(#clip0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.6631 12.422C29.6042 14.6796 25.1693 15.8801 21.3502 15.8801C15.9981 15.8801 11.1783 13.9016 7.53078 10.6082C7.24472 10.3493 7.49983 9.99617 7.84404 10.1964C11.779 12.4863 16.6457 13.8655 21.6714 13.8655C25.0619 13.8655 28.7892 13.1616 32.2186 11.7069C32.7358 11.4879 33.1696 12.0478 32.6631 12.422Z"
        fill="#F79C34"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.9363 10.9683C33.5448 10.4675 31.3501 10.731 30.3639 10.8492C30.0652 10.8853 30.0187 10.6241 30.2879 10.4351C32.039 9.20505 34.9085 9.56004 35.2419 9.97225C35.5777 10.3873 35.1533 13.2643 33.5129 14.6369C33.2601 14.8479 33.02 14.7354 33.1321 14.4568C33.5016 13.5344 34.3279 11.4701 33.9363 10.9683Z"
        fill="#F79C34"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.4335 1.74648V0.551598C30.4344 0.369646 30.5713 0.248657 30.7364 0.249126L36.0927 0.248657C36.2639 0.248657 36.4018 0.372929 36.4018 0.550191V1.57484C36.3999 1.74695 36.255 1.9711 35.9985 2.32704L33.2237 6.28871C34.2535 6.2648 35.3429 6.41908 36.2789 6.94477C36.4899 7.06342 36.5467 7.23927 36.5631 7.41138V8.68645C36.5631 8.8623 36.3708 9.06536 36.1682 8.95984C34.5199 8.09604 32.3322 8.00178 30.509 8.97016C30.3228 9.06958 30.1282 8.86887 30.1282 8.69301V7.48078C30.1282 7.2871 30.1319 6.95509 30.3275 6.65965L33.5416 2.04801L30.743 2.04754C30.5718 2.04754 30.4344 1.92562 30.4335 1.74648Z"
        fill="#333E47"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.895 9.21072H9.26544C9.11022 9.20087 8.98642 9.08457 8.97375 8.93544L8.97516 0.57175C8.97516 0.404804 9.11585 0.271154 9.28983 0.271154L10.8073 0.270685C10.9658 0.278657 11.0934 0.398708 11.1032 0.551585V1.6433H11.1342C11.5295 0.587225 12.2747 0.0943604 13.2787 0.0943604C14.2977 0.0943604 14.9369 0.587225 15.3932 1.6433C15.7885 0.587225 16.6861 0.0943604 17.6441 0.0943604C18.3288 0.0943604 19.074 0.376198 19.5303 1.00975C20.0475 1.71411 19.9415 2.73454 19.9415 3.63211L19.9396 8.91012C19.9396 9.0766 19.7994 9.21072 19.6254 9.21072H17.9982C17.8341 9.2004 17.7056 9.07097 17.7056 8.91059L17.7051 4.47668C17.7051 4.12497 17.7356 3.24522 17.6591 2.91086C17.5377 2.34719 17.1728 2.18868 16.7016 2.18868C16.3058 2.18868 15.895 2.4527 15.7275 2.87476C15.5601 3.29775 15.5756 4.00117 15.5756 4.47668V8.91012C15.5756 9.0766 15.4349 9.21072 15.2614 9.21072H13.6337C13.47 9.2004 13.3411 9.07097 13.3411 8.91059L13.3392 4.47668C13.3392 3.54394 13.4916 2.1718 12.3356 2.1718C11.1642 2.1718 11.2102 3.50877 11.2102 4.47668L11.2092 8.91012C11.2092 9.0766 11.0685 9.21072 10.895 9.21072Z"
        fill="#333E47"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.0273 1.8018C39.8259 1.8018 39.7499 3.43796 39.7499 4.45886C39.7499 5.47976 39.7349 7.66225 41.0128 7.66225C42.2752 7.66225 42.3362 5.90229 42.3362 4.82886C42.3362 4.12497 42.3052 3.27993 42.0923 2.61121C41.9099 2.03018 41.5446 1.8018 41.0273 1.8018ZM41.0128 0.0943604C43.4312 0.0943604 44.7391 2.1718 44.7391 4.81151C44.7391 7.36259 43.2942 9.38704 41.0128 9.38704C38.6394 9.38704 37.3466 7.31007 37.3466 4.72335C37.3466 2.11834 38.6545 0.0943604 41.0128 0.0943604Z"
        fill="#333E47"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.8758 9.21072H46.2519C46.0892 9.2004 45.9597 9.07097 45.9597 8.91059L45.9569 0.544082C45.971 0.390736 46.106 0.271154 46.2702 0.271154L47.7816 0.270685C47.9241 0.278188 48.0414 0.374792 48.0714 0.50469V1.78398H48.1019C48.5586 0.639747 49.1969 0.0943604 50.3223 0.0943604C51.0525 0.0943604 51.7676 0.358378 52.2239 1.08009C52.6497 1.74881 52.6497 2.87476 52.6497 3.68463V8.94764C52.6319 9.09583 52.4987 9.21072 52.3374 9.21072H50.7041C50.5531 9.20134 50.4316 9.0902 50.4138 8.94764V4.40681C50.4138 3.49142 50.5207 2.15351 49.3948 2.15351C48.9994 2.15351 48.6341 2.41753 48.4517 2.82223C48.2233 3.33292 48.1929 3.84313 48.1929 4.40681V8.91012C48.19 9.0766 48.0494 9.21072 47.8758 9.21072Z"
        fill="#333E47"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.8059 9.19007C27.6985 9.28668 27.5433 9.29324 27.4213 9.22806C26.8802 8.77834 26.7831 8.57059 26.4872 8.14197C25.5929 9.05314 24.9593 9.32654 23.8006 9.32654C22.4289 9.32654 21.3616 8.47962 21.3616 6.78671C21.3616 5.46381 22.0786 4.56437 23.0995 4.12356C23.9835 3.73527 25.2182 3.66492 26.1636 3.55894V3.34745C26.1636 2.95869 26.1941 2.50099 25.9652 2.1657C25.7669 1.86557 25.3861 1.74223 25.0508 1.74223C24.429 1.74223 23.877 2.06065 23.741 2.72046C23.7124 2.86724 23.6055 3.01309 23.4582 3.02059L21.8784 2.84942C21.7452 2.81894 21.597 2.71202 21.635 2.5085C21.9937 0.619104 23.681 0.0301044 25.2145 0.0141602H25.3354C26.1205 0.0240081 27.1231 0.239255 27.7336 0.824972C28.5266 1.56591 28.4497 2.55352 28.4497 3.62975V6.16817C28.4497 6.93209 28.7672 7.26692 29.065 7.67818C29.1696 7.82684 29.1925 8.0027 29.0598 8.11149C28.7269 8.39052 28.1355 8.90401 27.8106 9.19382L27.8059 9.19007ZM26.1636 5.21714C26.1636 5.8521 26.1786 6.38107 25.8588 6.94569C25.5995 7.40385 25.1877 7.68616 24.7305 7.68616C24.1059 7.68616 23.7401 7.21017 23.7401 6.50441C23.7401 5.11632 24.9847 4.86402 26.1636 4.86402V5.21714Z"
        fill="#333E47"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.44476 9.19239C6.3369 9.289 6.18121 9.29556 6.05975 9.23038C5.51812 8.78066 5.42104 8.57291 5.12561 8.14429C4.23085 9.05546 3.59777 9.32886 2.439 9.32886C1.06733 9.32886 0 8.48194 0 6.78903C0 5.46613 0.716084 4.56669 1.73792 4.12588C2.62189 3.73759 3.85663 3.66724 4.80156 3.56126V3.34977C4.80156 2.96101 4.83251 2.50331 4.60367 2.16802C4.4053 1.86789 4.02405 1.74455 3.68922 1.74455C3.06739 1.74455 2.5145 2.06297 2.37944 2.72278C2.35131 2.86956 2.24392 3.0154 2.0962 3.02291L0.516781 2.85174C0.383131 2.82126 0.234943 2.71434 0.273397 2.51082C0.631205 0.621423 2.31895 0.0324237 3.85288 0.0164795H3.97387C4.75889 0.0263274 5.7615 0.241575 6.37207 0.827291C7.16459 1.56823 7.08816 2.55584 7.08816 3.63207V6.17049C7.08816 6.93441 7.40563 7.26924 7.70342 7.6805C7.80705 7.82916 7.83097 8.00502 7.69826 8.11381C7.3653 8.39284 6.77349 8.90634 6.44851 9.19615L6.44476 9.19239ZM4.80156 5.21946C4.80156 5.85442 4.81704 6.38339 4.49675 6.94801C4.23789 7.40617 3.82615 7.68848 3.36846 7.68848C2.74382 7.68848 2.37804 7.21249 2.37804 6.50673C2.37804 5.11864 3.6231 4.86634 4.80156 4.86634V5.21946Z"
        fill="#333E47"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M74.9254 12.211C74.9254 11.999 74.9254 11.8086 74.9254 11.5966C74.9254 11.4217 75.0112 11.3012 75.1936 11.312C75.5341 11.3603 76.0157 11.4086 76.3575 11.3383C76.804 11.2454 77.1243 10.9289 77.3137 10.4951C77.5806 9.88451 77.7574 9.39212 77.869 9.06901L74.4789 0.671085C74.4217 0.528525 74.4048 0.264038 74.6895 0.264038H75.8745C76.1006 0.264038 76.1925 0.407536 76.2431 0.548221L78.7009 7.36954L81.047 0.548221C81.0949 0.408474 81.191 0.264038 81.4152 0.264038H82.5327C82.8154 0.264038 82.7995 0.528056 82.7432 0.671085L79.3804 9.33116C78.9452 10.4838 78.3656 12.3198 77.06 12.6386C76.4054 12.8098 75.5796 12.7479 75.0947 12.5449C74.9723 12.4834 74.9254 12.3193 74.9254 12.211Z"
        fill="#333E47"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M73.3444 8.69911C73.3444 8.85527 73.2164 8.9833 73.0593 8.9833H72.2236C72.0431 8.9833 71.9197 8.85293 71.8968 8.69911L71.8128 8.13028C71.4287 8.45526 70.9574 8.74085 70.4463 8.93968C69.4634 9.32141 68.3304 9.38472 67.37 8.79478C66.6755 8.36851 66.3069 7.53518 66.3069 6.6756C66.3069 6.01063 66.5118 5.35129 66.9662 4.8725C67.5726 4.21831 68.4514 3.89708 69.5126 3.89708C70.1537 3.89708 71.0709 3.97258 71.7382 4.19064V3.04594C71.7382 1.88201 71.2482 1.37836 69.9562 1.37836C68.9686 1.37836 68.2132 1.52749 67.1618 1.85434C66.9934 1.85997 66.8949 1.73195 66.8949 1.57579V0.923013C66.8949 0.766384 67.0286 0.614913 67.1735 0.568956C67.9243 0.241631 68.9879 0.0376379 70.1185 0.00012207C71.5924 0.00012207 73.3444 0.332607 73.3444 2.5995V8.69911ZM71.7382 7.02778V5.30065C71.1774 5.1473 70.2493 5.08352 69.8901 5.08352C69.3227 5.08352 68.7013 5.21764 68.3768 5.56701C68.1344 5.82305 68.0246 6.19071 68.0246 6.54617C68.0246 7.00574 68.1836 7.46672 68.555 7.69463C68.9865 7.98772 69.6556 7.95208 70.2845 7.77341C70.889 7.60178 71.4564 7.29837 71.7382 7.02778Z"
        fill="#333E47"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M61.0228 1.40412C62.877 1.40412 63.3821 2.86208 63.3821 4.53154C63.3924 5.65702 63.1856 6.66056 62.6027 7.23503C62.1666 7.66505 61.6793 7.78229 60.9459 7.78229C60.2931 7.78229 59.4345 7.44183 58.793 6.96773V2.1943C59.4607 1.68127 60.3128 1.40412 61.0228 1.40412ZM58.5088 12.5107H57.3894C57.2328 12.5107 57.1047 12.3827 57.1047 12.2265C57.1047 8.33893 57.1047 4.45088 57.1047 0.563298C57.1047 0.407138 57.2328 0.279115 57.3894 0.279115H58.2462C58.4262 0.279115 58.5496 0.409483 58.5725 0.563298L58.6626 1.17434C59.465 0.462005 60.498 0.00524902 61.4824 0.00524902C64.2393 0.00524902 65.1458 2.27684 65.1458 4.63893C65.1458 7.16609 63.7591 9.19664 61.4139 9.19664C60.4263 9.19664 59.5029 8.83226 58.793 8.19918V12.2265C58.793 12.3827 58.6649 12.5107 58.5088 12.5107Z"
        fill="#333E47"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="82.7764" height="15.88" fill="white" />
      </clipPath>
    </defs>
  </Svg>
)

export default AmazonPay
