import React, { FC } from 'react'

import { FormRow, FormColumn } from 'containers/cart/styled'
import FormField from 'components/form-field'
import SelectField from 'components/form-field/select'
import usStatesList from 'containers/cart/us-states-list'

type Props = {
  fieldsPrefix: string
}

const AddressForm: FC<Props> = ({ fieldsPrefix }) => (
  <div>
    <FormRow>
      <FormColumn>
        <FormField
          id="first-name-field"
          type="text"
          name={`${fieldsPrefix}.firstName`}
          label="First name"
          required
          placeholder="First name"
        />
      </FormColumn>
      <FormColumn>
        <FormField
          id="last-name-field"
          type="text"
          name={`${fieldsPrefix}.lastName`}
          label="Last name"
          required
          placeholder="Last name"
        />
      </FormColumn>
    </FormRow>
    <FormRow>
      <FormColumn>
        <FormField
          id="shipping-address-field"
          type="text"
          name={`${fieldsPrefix}.shippingAddress`}
          label="Address"
          required
          placeholder="Address"
        />
      </FormColumn>
      <FormColumn>
        <FormField
          id="shipping-address2-field"
          type="text"
          name={`${fieldsPrefix}.shippingAddress2`}
          label="Address line 2"
          placeholder="Suite, apartment, unit"
        />
      </FormColumn>
    </FormRow>
    <FormRow>
      <FormColumn>
        <FormField
          id="city-field"
          type="text"
          name={`${fieldsPrefix}.city`}
          label="City"
          required
          placeholder="City"
        />
      </FormColumn>
      <FormColumn>
        <FormField
          id="zip-field"
          type="text"
          name={`${fieldsPrefix}.zipCode`}
          label="Zip code"
          required
          placeholder="Zip code"
        />
      </FormColumn>
    </FormRow>
    <FormRow>
      <FormColumn>
        <SelectField
          id="state-field"
          name={`${fieldsPrefix}.state`}
          label="State"
          required
          placeholder="Select a state"
          options={usStatesList}
        />
      </FormColumn>
      <FormColumn>
        <FormField
          id="country-field"
          type="text"
          name={`${fieldsPrefix}.country`}
          label="Country"
          required
          placeholder="Country"
          disabled
          disclaimer="We are currently shipping only to the United States."
        />
      </FormColumn>
    </FormRow>
  </div>
)

export default AddressForm
