import styled, { css } from 'styled-components'
import { font, heading } from 'styles/typography'
import mq from 'styles/mq'
import theme from 'styles/theme'
import Button from 'components/button'
import ApplePay from 'components/icons/apple-pay'
import GooglePay from 'components/icons/google-pay'
import AmazonPay from 'components/icons/amazon-pay'
import Paypal from 'components/icons/paypal'
import ShoppingCart from 'components/icons/shopping-cart'
import ArrowDown from '../arrow-down'

const ButtonStyled = styled.button`
  background: none;
  font-family: ${font.headings};
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: bold;
  border: 0.2rem solid #172126;
  cursor: pointer;
`

export const PaymentsWrap = styled.div`
  display: flex;
  flex-direction: column;

  ${mq.medium} {
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
  }
`

export const PaymentServicesWrap = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 2.4rem;

  ${mq.medium} {
    width: 24.8rem;
    margin-bottom: 0;
  }
`

export const PaymentServiceButton = styled(ButtonStyled as any)`
  margin-bottom: 1.2rem;
  display: inline-flex;
  width: 48.5%;
  height: 5rem;
  align-items: center;
  justify-content: center;

  &:nth-child(odd) {
    margin-right: 0.8rem;
  }

  ${mq.medium} {
    max-width: 12rem;
    width: 12rem;
  }
`

export const StandardPaymentWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;

  &:before {
    display: block;
    margin-right: 1.6rem;
    ${heading.xs};
    margin-bottom: 1rem;

    ${mq.medium} {
      margin-bottom: 0;
    }
  }

  ${mq.medium} {
    flex-direction: row;
    justify-content: flex-end;
  }
`

export const CardsPreview = styled.div`
  margin-top: 1.2rem;

  ${mq.medium} {
    width: 100%;
  }
`

export const StandardPaymentButton = styled(Button as any)``

export const ApplePayIcon = styled(ApplePay)`
  width: 5rem;
  height: 2.1rem;
`

export const GooglePayIcon = styled(GooglePay)`
  width: 5.3rem;
  height: 2.2rem;
`

export const AmazonPayIcon = styled(AmazonPay)`
  width: 8.3rem;
  height: 1.6rem;
`

export const PayPalIcon = styled(Paypal)`
  width: 8rem;
  height: 2.2rem;
`

export const Total = styled.h3`
  ${heading.s};
  margin-top: 4.557rem;
  padding-bottom: 0;
`

export const Title = styled.h2`
  margin-bottom: 3.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`

export const ArrowDownStyled = styled(ArrowDown)<{ upsideDown?: boolean }>`
  ${({ upsideDown }) =>
    upsideDown &&
    css`
      transform: rotateX(180deg);
    `}
`

export const CartIcon = styled(ShoppingCart)`
  width: 20rem;
  height: 20rem;
  color: ${theme.color.border.light};
`

export const IconContainer = styled.div`
  width: 20rem;
  height: 18rem;
  margin: 0 auto;
`

export const EmptyState = styled.div`
  text-align: center;
  padding: 6rem 0;
`

export const EmptyStateCopy = styled.h2`
  ${heading.s};
  color: ${theme.color.border.light};
  margin-bottom: 4rem;
`

export const Box = styled.section`
  background: #fff;
  margin-bottom: 4.4rem;
  padding: 4rem 2.4rem;
  width: 100vw;

  ${mq.medium} {
    padding: 4rem;
  }

  ${mq.desktop} {
    width: initial;
  }
`