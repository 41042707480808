import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { useFormikContext } from 'formik'
import Img from 'gatsby-image'
import {
  Box,
  CheckoutWrap,
  ImagePlaceholder,
  LinkStyled,
} from 'containers/cart/styled'
import {
  Table,
  TFoot,
  Wrap,
  ImageWrap,
  TableWrap,
  UnstyledBox,
  FormError,
} from './styled'
import { useCartContext } from 'context/cart'
import CheckboxField from 'components/form-field/checkbox'
import Button from 'components/button'
import Spinner from 'components/spinner'
import useTotalPrice from 'hooks/use-total-price'
import { formatCurrency } from 'utils/format'
import urls from 'common/urls'

type QueryResult = {
  allStripeSku: AllStripeSkuType
  kitImage: FluidImage
}

const query = graphql`
  query {
    allStripeSku(sort: { fields: created, order: ASC }) {
      edges {
        node {
          id
          price
          attributes {
            name
          }
          product {
            name
          }
          created
        }
      }
    }
    kitImage: file(relativePath: { eq: "kit-closed.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    kitPlusCryoImage: file(relativePath: { eq: "kit-closed.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

type FormValues = {
  promoCode: string
  ship: string
}

const OrderReview = () => {
  const { getCount, items, promoCode } = useCartContext()
  // eslint-disable-next-line no-shadow
  const { status, dirty, isValid, isSubmitting, values } = useFormikContext<
    FormValues
  >()
  const {
    totalOrderPrice,
    // taxes,
    discount,
    totalKitProductsPrice,
    totalCryoKitProductsPrice,
    totalVasectomyKitProductsPrice,
  } = useTotalPrice()
  // TODO: refactor this and the table rows of the template to use
  // the useAllKitProduct hook bc this is ridiculous
  const { kitImage, allStripeSku } = useStaticQuery<QueryResult>(query)
  const kitSku = allStripeSku.edges[0]
  const cryoKitSku = allStripeSku.edges[1]
  const vasectomyKitSku = allStripeSku.edges[2]
  const kitCartItemQuantity = items[kitSku.node.id]
  const cryoKitCartItemQuantity = items[cryoKitSku.node.id]
  const vasectomyKitCartItemQuantity = items[vasectomyKitSku.node.id]
  const hasKit = kitCartItemQuantity > 0
  const hasCryoKit = cryoKitCartItemQuantity > 0
  const hasVasectomyKit = vasectomyKitCartItemQuantity > 0
  const totalSubscriptionPrice = cryoKitCartItemQuantity * 140

  const renderShippingSnippet = () => {
    // Single kit orders are automatically upgraded to free expedited shipping, which
    // is equivalent to "UPS 2nd Day Air Saver" in our backend. For the customer, they
    // just see "Expedited shipping".
    const shipping_amount = values.ship
    if (getCount() === 1) {
      return (
        <tr>
          <td>UPS Expedited Shipping (arrives in 2-3 business days)</td>
          <td>Free</td>
        </tr>
      )
    } else if (shipping_amount === '0') {
      return (
        <tr>
          <td>UPS Ground Shipping (arrives in 5-7 business days)</td>
          <td>Free</td>
        </tr>
      )
    } else if (shipping_amount === '3000') {
      return (
        <tr>
          <td>UPS Expedited Shipping (arrives in 2-3 business days)</td>
          <td>$30.00</td>
        </tr>
      )
    }
    return null
  }

  return (
    <div>
      <Box>
        <h2>Order review</h2>
        <Wrap>
          <TableWrap>
            <Table>
              <thead>
                {hasKit ? (
                  <tr>
                    <th>Semen Analysis Kit ({kitCartItemQuantity})</th>
                    <th>${formatCurrency(totalKitProductsPrice)}</th>
                  </tr>
                ) : null}

                {hasCryoKit ? (
                  <tr>
                    <th>
                      Semen Analysis Kit + Cryopreservation (
                      {cryoKitCartItemQuantity})
                    </th>
                    <th>${formatCurrency(totalCryoKitProductsPrice)}</th>
                  </tr>
                ) : null}

                {hasVasectomyKit ? (
                  <tr>
                    <th>Vasectomy Test ({vasectomyKitCartItemQuantity})</th>
                    <th>${formatCurrency(totalVasectomyKitProductsPrice)}</th>
                  </tr>
                ) : null}
              </thead>
              <tbody>
                {promoCode && discount !== 0 && (
                  <tr>
                    <td>
                      Referral code (<em>{values.promoCode}</em>)
                    </td>
                    {promoCode.percent_off && (
                      <td>
                        {promoCode.percent_off}% (-${formatCurrency(discount)})
                      </td>
                    )}
                    {promoCode.amount_off && (
                      <td>-${formatCurrency(discount)}</td>
                    )}
                  </tr>
                )}
                <tr>
                  <td>Doctor approved results</td>
                  <td>Included</td>
                </tr>

                {renderShippingSnippet()}
                {/* <tr>
                  <td>Taxes</td>
                  <td>${formatCurrency(taxes)}</td>
                </tr> */}
              </tbody>
              <TFoot>
                <tr>
                  <td>Total {hasCryoKit ? 'payment today' : ''}</td>
                  <td>${formatCurrency(totalOrderPrice)}</td>
                </tr>
                {hasCryoKit ? (
                  <tr>
                    <td>
                      Annual storage fee - billed when your sample is approved
                      and stored.
                    </td>
                    <td>{`$${totalSubscriptionPrice}/year`}</td>
                  </tr>
                ) : null}
              </TFoot>
            </Table>
          </TableWrap>
          <ImageWrap>
            <ImagePlaceholder>
              <Img fluid={kitImage.childImageSharp.fluid} alt="" />
            </ImagePlaceholder>
          </ImageWrap>
        </Wrap>
      </Box>
      <UnstyledBox>
        <CheckboxField
          name="terms_and_conditions"
          label={
            <span>
              By checking this box, I agree to<span translate="no"> Fellow&apos;s </span>
              <LinkStyled href={urls.terms} target="_blank">
                Terms of Service
              </LinkStyled>{' '}
              and{' '}
              <LinkStyled href={urls.privacy} target="_blank">
                Privacy Policy
              </LinkStyled>.
            </span>
          }
        />
        {discount !== 0 && promoCode && promoCode.is_referral && (
            <CheckboxField
              name="clinic_sharing_agreement"
              label={
                <span>
                  By checking this box I understand and agree that my personal information and test results will be
                  shared with the clinic associated with the referral code {`${values.promoCode}`}.
                </span>
              }
            />
          )
        }
        <CheckoutWrap>
          {status && status.error && status.error === 'payment_method_invalid' && (
            <FormError>
              Oops, your payment didn&apos;t go through. <br />
              Please verify that your billing information is correct and try
              again. <br />
              If problems persist, please reach out to{' '}
              <a href="mailto:support@meetfellow.com">support@meetfellow.com</a>
            </FormError>
          )}
          {status &&
            status.error &&
            status.error === 'pay_endpoint_failed' &&
            !values.promoCode && (
              <FormError>
                Oops, we were unable to complete your purchase. <br />
                Please verify your billing information and try again. <br />
                If problems persist, please reach out to{' '}
                <a href="mailto:support@meetfellow.com">
                  support@meetfellow.com
                </a>
              </FormError>
            )}
          {status &&
            status.error &&
            status.error === 'pay_endpoint_failed' &&
            values.promoCode && (
              <FormError>
                Oops, we were unable to complete your purchase. <br />
                Please verify that your referral code and billing information
                are correct and try again. <br />
                If problems persist, please reach out to{' '}
                <a href="mailto:support@meetfellow.com">
                  support@meetfellow.com
                </a>
              </FormError>
            )}
          <Button
            className="complete-purchase-button"
            disabled={!(isValid && dirty) || isSubmitting}
            type="submit"
          >
            {isSubmitting ? 'Processing Payment' : 'Complete purchase'}
            {isSubmitting && <Spinner />}
          </Button>
        </CheckoutWrap>
      </UnstyledBox>
    </div>
  )
}

export default OrderReview
