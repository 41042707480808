import React, { FC, SVGProps } from 'react'
import Svg from 'components/svg'

type Props = SVGProps<SVGSVGElement> & {
  checked?: boolean
}

const Checkbox: FC<Props> = props => (
  <Svg {...props} viewbox="0 0 20 20" fill="none">
    {props.checked && (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0685 5L7.47727 13.0153L4.94321 10.2724L4 11.2488L7.4656 15L16 5.9885L15.0685 5Z"
        fill="currentColor"
        stroke="currentColor"
      />
    )}
    <rect x="1" y="1" width="18" height="18" stroke="currentColor" strokeWidth="2" />
  </Svg>
)

export default Checkbox
