import { useStaticQuery, graphql } from 'gatsby'
import { useCartContext } from 'context/cart'

type QueryResult = {
  allStripeSku: AllStripeSkuType
}

const query = graphql`
  query {
    allStripeSku(sort: { fields: created, order: ASC }) {
      edges {
        node {
          id
          price
          product {
            name
          }
        }
      }
    }
  }
`

const calculateTotalPrice = (products: AllStripeSkuType, items: CartItems) => {
  let totalPrice = 0
  for (const [sku, quantity] of Object.entries(items)) {
    const stripeNode = products.edges.find(edge => edge.node.id === sku)
    if (stripeNode) {
      totalPrice += quantity * stripeNode.node.price
    }
  }
  return totalPrice
}

const calculateTotalProductPrice = (product: StripeSku, itemQuantity: number) =>
  product.price * itemQuantity

const applyPromoCode = (totalPrice: number, promoCode: PromoCode) => {
  if ('amount_off' in promoCode && typeof promoCode.amount_off === 'number') {
    return totalPrice - promoCode.amount_off
  } else if (
    'percent_off' in promoCode &&
    typeof promoCode.percent_off === 'number'
  ) {
    return (totalPrice * (100 - promoCode.percent_off)) / 100
  } else return totalPrice
}

const taxes = 0

const useTotalPrice = () => {
  const { items, promoCode, ship } = useCartContext()
  // TODO: refactor this to use useAllKitProduct
  const { allStripeSku } = useStaticQuery<QueryResult>(query)
  const kitSku = allStripeSku.edges[0]?.node
  const cryoKitSku = allStripeSku.edges[1]?.node
  const vasectomyKitSku = allStripeSku.edges[2]?.node

  const kitCartItemQuantity = items[kitSku.id]

  const totalKitProductsPrice = calculateTotalProductPrice(
    kitSku,
    kitCartItemQuantity
  )

  let totalCryoKitProductsPrice = 0
  if (cryoKitSku) {
    const cryoKitCartItemQuantity = items[cryoKitSku.id]
    totalCryoKitProductsPrice = calculateTotalProductPrice(
      cryoKitSku,
      cryoKitCartItemQuantity
    )
  }

  let totalVasectomyKitProductsPrice = 0
  if (vasectomyKitSku) {
    const vasectomyKitCartItemQuantity = items[vasectomyKitSku.id]
    totalVasectomyKitProductsPrice = calculateTotalProductPrice(
      vasectomyKitSku,
      vasectomyKitCartItemQuantity
    )
  }

  const totalProductsPrice = calculateTotalPrice(allStripeSku, items)

  let totalOrderPrice = totalProductsPrice + taxes
  let discount = 0
  if (promoCode) {
    if ('amount_off' in promoCode && typeof promoCode.amount_off === 'number') {
      discount = promoCode.amount_off
    } else if (
      'percent_off' in promoCode &&
      typeof promoCode.percent_off === 'number'
    ) {
      discount = (totalProductsPrice * promoCode.percent_off) / 100
    }
    totalOrderPrice = applyPromoCode(totalProductsPrice, promoCode)
  }

  //Add Shipping Cost at the End
  if (ship) {
    totalOrderPrice = totalOrderPrice + ship
  }

  return {
    totalProductsPrice,
    totalOrderPrice,
    taxes,
    discount,
    ship,
    totalKitProductsPrice,
    totalCryoKitProductsPrice,
    totalVasectomyKitProductsPrice,
  }
}

export default useTotalPrice
