import { useCallback, useState } from 'react'
import { useFormikContext } from 'formik'
import { cardOptions } from 'components/form-field/card-details/styled'

const options = cardOptions

const useCardElementsHelpers = (props: { name: string }) => {
  const {
    setFieldValue,
    setFieldTouched,
    setFieldError,
    getFieldMeta,
    handleBlur,
  } = useFormikContext()
  const [stripeError, setStripeError] = useState<Maybe<string>>(null)
  const meta = getFieldMeta(props.name)
  const { error, touched } = meta

  const onChange = useCallback(
    e => {
      setFieldError(props.name, e.error && e.error.message)
      setStripeError(e.error && e.error.message)
      setFieldValue(props.name, e.complete)
    },
    [props.name, setFieldError, setFieldValue]
  )

  const onBlur = useCallback(
    () => {
      setFieldTouched(props.name, true)
      handleBlur(props.name)
    },
    [handleBlur, props.name, setFieldTouched]
  )

  const hasError = Boolean((stripeError || error) && touched)
  const hasSuccess = Boolean(!error && !stripeError && touched)

  return {
    hasError,
    hasSuccess,
    error: stripeError || error,
    field: {
      onChange,
      onBlur,
      options,
      id: props.name,
    },
  }
}

export default useCardElementsHelpers
