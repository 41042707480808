import React, { FC, SVGProps } from 'react'
import Svg from 'components/svg'

type Props = SVGProps<SVGSVGElement> & {
  checked?: boolean
}

const Radio: FC<Props> = props => (
  <Svg {...props} viewbox="0 0 20 20" fill="none">
    <rect
      x="1"
      y="1"
      width="18"
      height="18"
      rx="9"
      stroke="currentColor"
      strokeWidth="2"
    />
    {props.checked && (
      <rect
        x="3.75"
        y="3.75"
        width="12.5"
        height="12.5"
        rx="6.25"
        fill="currentColor"
      />
    )}
  </Svg>
)

export default Radio
