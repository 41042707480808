import React, { FC, SVGProps } from 'react'
import Svg from 'components/svg'

const GooglePay: FC<SVGProps<SVGSVGElement>> = props => (
  <Svg {...props} viewbox="0 0 53 22">
    <path
      d="M24.7807 10.3121V16.5042H22.8461V1.21903H27.9771C29.2148 1.19465 30.4164 1.67003 31.2936 2.54765C33.048 4.20536 33.1561 6.99667 31.5099 8.78847C31.4378 8.8616 31.3657 8.93474 31.2936 9.00787C30.3924 9.8733 29.2868 10.3121 27.9771 10.3121H24.7807ZM24.7807 3.09616V8.43499H28.0251C28.7461 8.45936 29.4431 8.16683 29.9357 7.64269C30.9451 6.58224 30.9211 4.87577 29.8757 3.85188C29.383 3.36432 28.7221 3.09616 28.0251 3.09616H24.7807Z"
      fill="#62686C"
    />
    <path
      d="M37.1457 5.70483C38.5757 5.70483 39.7052 6.09489 40.5344 6.8628C41.3635 7.63071 41.772 8.70335 41.772 10.0563V16.5044H39.9215V15.0539H39.8374C39.0323 16.2484 37.9749 16.8457 36.6411 16.8457C35.5115 16.8457 34.5502 16.5044 33.7932 15.8218C33.0482 15.188 32.6276 14.2494 32.6516 13.2621C32.6516 12.1773 33.0602 11.324 33.8653 10.678C34.6704 10.032 35.7518 9.71505 37.0977 9.71505C38.2512 9.71505 39.1885 9.93445 39.9335 10.3489V9.89789C39.9335 9.22749 39.6451 8.59365 39.1405 8.15484C38.6238 7.69166 37.9629 7.43569 37.2779 7.43569C36.1964 7.43569 35.3433 7.89887 34.7184 8.82525L33.0121 7.74042C33.9253 6.38742 35.3072 5.70483 37.1457 5.70483ZM34.6463 13.2986C34.6463 13.8106 34.8867 14.286 35.2832 14.5785C35.7158 14.9198 36.2445 15.1026 36.7852 15.0904C37.6024 15.0904 38.3834 14.7613 38.9602 14.1763C39.5971 13.5668 39.9215 12.8477 39.9215 12.0188C39.3207 11.5312 38.4796 11.2874 37.3981 11.2996C36.617 11.2996 35.9561 11.4947 35.4274 11.8725C34.9107 12.2504 34.6463 12.7258 34.6463 13.2986Z"
      fill="#62686C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M52.3945 6.04639L45.9297 21.0999H43.9349L46.3382 15.8342L42.0964 6.04639H44.1993L47.2635 13.5549H47.2995L50.2916 6.04639H52.3945Z"
      fill="#62686C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.9583 8.97175C16.9583 8.37448 16.9103 7.77722 16.8142 7.19214H8.65503V10.5685H13.3294C13.1371 11.6534 12.5123 12.6285 11.599 13.2379V15.432H14.3868C16.0211 13.9083 16.9583 11.6534 16.9583 8.97175Z"
      fill="#4285F4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.655 17.5528C10.9862 17.5528 12.9569 16.7727 14.3868 15.4319L11.599 13.2379C10.8179 13.7742 9.82059 14.0789 8.655 14.0789C6.39592 14.0789 4.48532 12.5309 3.80038 10.4587H0.928467V12.7259C2.39447 15.6879 5.38655 17.5528 8.655 17.5528Z"
      fill="#34A853"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.80018 10.4588C3.43969 9.37395 3.43969 8.1916 3.80018 7.09458V4.8396H0.928266C-0.309422 7.31399 -0.309422 10.2394 0.928266 12.7138L3.80018 10.4588Z"
      fill="#FBBC04"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.655 3.47443C9.89269 3.45005 11.0823 3.92542 11.9715 4.79085L14.4469 2.27989C12.8727 0.792823 10.8059 -0.0238477 8.655 0.000530482C5.38654 0.000530482 2.39447 1.87765 0.928467 4.83961L3.80038 7.10678C4.48532 5.02244 6.39592 3.47443 8.655 3.47443Z"
      fill="#EA4335"
    />
  </Svg>
)

export default GooglePay
