import React, { FC, SVGProps } from 'react'
import Svg from 'components/svg'

const QuestionMark: FC<SVGProps<SVGSVGElement>> = props => (
  <Svg {...props} viewbox="0 0 26 26">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 23C18.5228 23 23 18.5228 23 13C23 7.47715 18.5228 3 13 3C7.47715 3 3 7.47715 3 13C3 18.5228 7.47715 23 13 23Z"
      stroke="currentColor"
      strokeWidth="3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.632 13.368C14.8 14.12 14.352 14.632 14.304 15.24H11.52C11.52 14.344 12.016 13.528 12.912 12.68C13.808 11.848 14.272 11.288 14.272 10.664C14.272 10.04 13.968 9.67204 13.344 9.67204C12.592 9.67204 12.24 10.072 12.24 11.208H9.31201V10.904C9.31201 8.37603 11.344 6.93604 13.504 6.93604C15.84 6.93604 17.344 8.64804 17.344 10.328C17.344 11.416 16.864 12.28 15.632 13.368ZM14.304 16.28V19H11.52V16.28H14.304Z"
      fill="currentColor"
    />
  </Svg>
)

export default QuestionMark
