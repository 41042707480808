import * as Yup from 'yup'

const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/u;
const zipRegExp = /(^\d{5}$)|(^\d{5}-\d{4}$)/u;
const POBoxRegExp =/\b[p]*(ost)*\.*\s*[o|0]*(ffice)*\.*\s*b[o|0]x\b/ui;

const shippingAddressValidation = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .min(2, 'First name must be at least two letters')
    .required('Required'),
  lastName: Yup.string()
    .trim()
    .min(2, 'Last name must be at least two letters')
    .required('Required'),
  shippingAddress: Yup.string()
    .trim()
    .required('Required')
    .test(
      'is-po-box',
      'Oh no! We currently can’t ship to PO Boxes. Consider shipping your kit to your work or a friends address. Your kit will arrive in a normal brown box, so it’s super discreet.',
      value => !POBoxRegExp.test(value)
    ),
  shippingAddress2: Yup.string()
    .trim()
    .test(
      'is-po-box',
      'Oh no! We currently can’t ship to PO Boxes. Consider shipping your kit to your work or a friends address. Your kit will arrive in a normal brown box, so it’s super discreet.',
      value => !POBoxRegExp.test(value)
    ),
  city: Yup.string()
    .trim()
    .min(2, 'City must be at least two letters')
    .required('Required'),
  zipCode: Yup.string()
    .matches(zipRegExp, 'Please enter a valid zip code')
    .required('Required'),
  state: Yup.string()
    .required('Required'),
  country: Yup.string()
    .required('Required'),
})

const billingAddressValidation = shippingAddressValidation.shape({
  shippingAddress: Yup.string()
    .trim()
    .required('Required'),
  shippingAddress2: Yup.string()
    .trim()
})

export const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please enter a valid email address')
    .required('Required'),
  phone: Yup.string()
    .matches(phoneRegExp, 'Please enter a valid phone number')
    .required('Required'),
  shipping: shippingAddressValidation,
  terms_and_conditions: Yup.bool()
    .oneOf([true], 'You must agree in order to purchase a Fellow kit.'),
  billing: Yup.object()
    .when('same_billing_address', {
      is: false,
      then: billingAddressValidation,
    }),
  card_number: Yup.bool().nullable().required('Required').oneOf([true], 'Required'),
  card_expiration: Yup.bool().nullable().required('Required').oneOf([true], 'Required'),
  card_cvc: Yup.bool().nullable().required('Required').oneOf([true], 'Required'),
})

export const initialValues = {
  email: '',
  phone: '',
  same_billing_address: true,
  shipping: {
    firstName: '',
    lastName: '',
    state: '',
    shippingAddress: '',
    shippingAddress2: '',
    city: '',
    zipCode: '',
    country: 'United States',
  },
  billing: {
    firstName: '',
    lastName: '',
    state: '',
    shippingAddress: '',
    shippingAddress2: '',
    city: '',
    zipCode: '',
    country: 'United States',
  },
  terms_and_conditions: false,
  clinic_sharing_agreement: false,
  promoCode: '',
  price: 0,
  card_number: null,
  card_expiration: null,
  card_cvc: null,
  ship: "0"
}
