import styled from 'styled-components'
import { font, paragraph } from 'styles/typography'
import { Box } from 'containers/cart/styled'
import mq from 'styles/mq'
import theme from 'styles/theme'

export const Table = styled.table`
  text-align: left;
  font-size: 1.4rem;
  border-collapse: collapse;
  width: 100%;

  td,
  th {
    padding-bottom: 1.6rem;
  }

  td:nth-child(even),
  th:nth-child(even) {
    text-align: right;
  }

  thead,
  tfoot {
    font-weight: bold;
    font-family: ${font.headings};
  }
`

export const TFoot = styled.tfoot`
  tr:first-of-type {
    td {
      border-top: 1px solid ${theme.color.border.blue};
      padding-top: 1.6rem;
    }
  }

  tr:last-of-type {
    td:first-of-type {
      font-family: ${font.base};
      font-weight: 300;
    }
  }
`

export const Wrap = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  ${mq.medium} {
    flex-direction: row;
    justify-content: space-between;
  }
`

export const ImageWrap = styled.div`
  display: none;

  ${mq.medium} {
    display: block;
  }
`

export const TableWrap = styled.div`
  flex: 1;

  ${mq.medium} {
    margin-right: 2rem;
  }
`

export const UnstyledBox = styled(Box as any)`
  background: none;
  padding-bottom: 0;
`

export const FormError = styled.div`
  font-family: ${font.base};
  ${paragraph.small};
  color: ${theme.color.error};
  padding-bottom: 2rem;
`
