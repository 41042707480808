import React, { FC, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { useCartContext } from 'context/cart'
import useAllKitProducts from 'hooks/use-all-kit-products'
import { ButtonLink } from 'components/button'
import {
  PaymentsWrap,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  PaymentServicesWrap,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  PaymentServiceButton,
  StandardPaymentWrap,
  StandardPaymentButton,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ApplePayIcon,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  GooglePayIcon,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  AmazonPayIcon,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  PayPalIcon,
  Title,
  ArrowDownStyled,
  CardsPreview,
  IconContainer,
  CartIcon,
  EmptyState,
  EmptyStateCopy,
  Box,
} from './styled'
import CartTable from './CartTable'
import Urls from 'common/urls'

type QueryResult = {
  cardsImage: FluidImage
}

const query = graphql`
  query {
    cardsImage: file(relativePath: { eq: "cards-resized.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 150, webpQuality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

type Props = {
  onClickTitle: () => void
  onCreditCardPay: () => void
  collapsed?: boolean
}

const Cart: FC<Props> = ({
  onCreditCardPay,
  collapsed = false,
  onClickTitle,
}) => {
  const { getCount } = useCartContext()
  const { cardsImage } = useStaticQuery<QueryResult>(query)

  return (
    <Box>
      <Title onClick={onClickTitle}>
        Your cart ({getCount()})
        {Boolean(getCount()) && (
          <ArrowDownStyled upsideDown={!collapsed} />
        )}
      </Title>
      {!collapsed && (
        getCount()? (
          <>
            <CartTable />
            <PaymentsWrap>
              {/* <PaymentServicesWrap>
              <PaymentServiceButton>
                <ApplePayIcon />
              </PaymentServiceButton>
              <PaymentServiceButton>
                <GooglePayIcon />
              </PaymentServiceButton>
              <PaymentServiceButton>
                <AmazonPayIcon />
              </PaymentServiceButton>
              <PaymentServiceButton>
                <PayPalIcon />
              </PaymentServiceButton>
            </PaymentServicesWrap> */}
              <StandardPaymentWrap>
                <StandardPaymentButton
                  id="pay-with-card"
                  onClick={onCreditCardPay}
                >
                  Pay Now
                </StandardPaymentButton>
                <CardsPreview>
                  <Img fluid={cardsImage.childImageSharp.fluid} alt="" />
                </CardsPreview>
              </StandardPaymentWrap>
            </PaymentsWrap>
          </>
        ) : (
          <EmptyState>
            <IconContainer>
              <CartIcon />
            </IconContainer>
            <EmptyStateCopy>There&apos;s nothing in your cart</EmptyStateCopy>
            <ButtonLink to={Urls.shop}>Browse products</ButtonLink>
          </EmptyState>
        )
      )}
    </Box>
  )
}

export default Cart
