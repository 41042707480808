import React, { FC, SVGProps } from 'react'
import Svg from 'components/svg'

const Check3: FC<SVGProps<SVGSVGElement>> = props => (
  <Svg {...props} viewbox="0 0 14 14">
    <path fillRule="evenodd" clipRule="evenodd" d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14Z" fill="currentColor"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M10.7846 4L5.60666 9.04664L3.70705 7.14864L3 7.82429L5.59791 10.42L11.4953 4.67195L10.7846 4Z" fill="white" stroke="white" strokeWidth="0.8"/>
  </Svg>
)

export default Check3
