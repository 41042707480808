import React, { FC, SVGProps } from 'react'
import Svg from 'components/svg'

const PlusCircle: FC<SVGProps<SVGSVGElement>> = props => (
  <Svg {...props} viewbox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
      stroke="currentColor"
      strokeWidth="3"
    />
    <path
      d="M8 12H16"
      stroke="currentColor"
      strokeWidth="3"
      strokeLinecap="square"
    />
    <path
      d="M12 15.5V8.7002"
      stroke="currentColor"
      strokeWidth="3"
      strokeLinecap="square"
    />
  </Svg>
)

export default PlusCircle
