import React, { FC } from 'react'
import { Address } from './styled'

const renderCityStateZip = (city: string, state: string, zipCode: string) => {
  // @ts-ignore
  return `${city}, ${state.value} ${zipCode}`
}

type ShippingType = Pick<PaymentRequestType, 'shipping'>

// @ts-ignore
const AddressPreview: FC<ShippingType> = ({ shipping }) => {
  const fullName = `${shipping.firstName} ${shipping.lastName}`
  const address1 = shipping.shippingAddress
  const address2 = shipping.shippingAddress2
  const city = shipping.city
  const state = shipping.state
  const country = shipping.country
  const zipCode = shipping.zipCode

  return (
    fullName &&
    address1 &&
    state &&
    country &&
    zipCode && (
      <Address className="fs-exclude">
        {fullName && (
          <>
            {fullName}
            <br />
          </>
        )}
        {address1 && (
          <>
            {address1}
            <br />
          </>
        )}
        {address2 && (
          <>
            {address2}
            <br />
          </>
        )}
        {city && state && zipCode && (
          <>
            {renderCityStateZip(city, state, zipCode)}
            <br />
          </>
        )}
        {country && country}
      </Address>
    )
  )
}

export default AddressPreview
