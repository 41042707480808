import styled, { css } from 'styled-components'

import Checkbox from 'components/icons/checkbox'
import Radio from 'components/icons/radio'
import theme from 'styles/theme'

export const CheckboxIcon = styled(Checkbox)`
  color: ${theme.color.accent.primary};
  width: 2rem;
  height: 2rem;
  display: inline-block;
  margin-right: 1.7rem;
`

export const RadioIcon = styled(Radio)`
  color: ${theme.color.accent.primary};
  width: 2rem;
  height: 2rem;
  display: inline-block;
  margin-right: 1.7rem;
`

export const Label = styled.label<{ disabled?: boolean }>`
  font-weight: 300;
  font-size: 1.4rem;
  line-height: 2.2rem;
  cursor: pointer;
  display: flex;
  align-items: flex-start;

  ${({ disabled }) =>
    disabled
      ? css`
          opacity: 0.3;
        `
      : css`
          &:hover {
            ${CheckboxIcon},
            ${RadioIcon} {
              color: ${theme.color.background.dark};
            }
          }
        `}
`

export const IconWrap = styled.span`
  display: inline-block;
`

export const CheckboxInput = styled.input`
  visibility: hidden;
  position: absolute;

  &:checked ~ ${CheckboxIcon} {
    color: ${theme.color.accent.primary};
  }
`
