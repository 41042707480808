import React, { FC, useCallback } from 'react'
import SimpleSelect from '../simple-select'
import { useField, useFormikContext } from 'formik'

import {
  Wrap,
  ValidationMessage,
  Label,
  ErrorIcon,
  SuccessIcon,
  SelectFieldInputWrap,
} from 'components/form-field/styled'

type Props = FormField & {
  options: ReadonlyArray<{
    value: string
    label: string
  }>
}

const SelectField: FC<Props> = props => {
  const { setFieldValue } = useFormikContext()
  const [field, meta] = useField({
    ...props,
    as: 'select',
  })

  const hasError = (meta.error && meta.touched) as boolean
  const hasSuccess = !meta.error && (meta.value as boolean)

  const onChange = useCallback(
    option => {
      setFieldValue(field.name, option)
    },
    [field.name, setFieldValue]
  )

  return (
    <Wrap>
      <Label htmlFor={props.id || props.name}>
        {props.label}
        {props.required && <sup>*</sup>}
      </Label>
      <SelectFieldInputWrap
        error={hasError}
        hasSuccess={hasSuccess}
      >
        <SimpleSelect
          {...props}
          value={field.value}
          options={props.options}
          onChange={onChange}
          hasSuccess={hasSuccess}
        />
        {hasSuccess && <SuccessIcon />}
        {hasError && <ErrorIcon />}
      </SelectFieldInputWrap>
      {hasError && <ValidationMessage>{meta.error}</ValidationMessage>}
    </Wrap>
  )
}

export default SelectField
