import styled from 'styled-components'
import theme from 'styles/theme'
import mq from 'styles/mq'
import Checkbox from 'components/icons/checkbox'
import { heading } from 'styles/typography'

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${theme.color.background.gray};
  justify-content: center;
  flex-grow: 1;
`

export const Title = styled.h1`
  ${heading.l};
  margin: 1rem 0;
  text-align: center;

  ${mq.medium} {
    margin: 3rem 0;
  }
`

export const Content = styled.div`
  margin: auto;

  ${mq.desktop} {
    width: 71.3rem;
  }
`

export const CheckoutWrap = styled.div`
  text-align: center;
  margin: 4.2rem 0 5.1rem;
`

export const Box = styled.section`
  background: #fff;
  margin-bottom: 2.4rem;
  padding: 4rem 2.4rem;

  ${mq.medium} {
    padding: 4rem;
  }

  ${mq.desktop} {
    width: initial;
  }
`

export const FormRow = styled.div`
  display: flex;
  flex-direction: column;

  ${mq.medium} {
    flex-direction: row;
    justify-content: space-between;
  }
`

export const FormColumn = styled.div`
  flex: 1;
  margin-bottom: 3.3rem;

  ${mq.medium} {
    &:nth-child(odd) {
      margin-right: 2.5rem;
    }
  }
`

export const ImagePlaceholder = styled.div`
  width: 215.58px;
  height: 223px;
  overflow: hidden;
`

export const LinkStyled = styled.a`
  color: ${theme.color.link.blue};
  font-weight: 500;
`

export const CheckboxStyled = styled(Checkbox)`
  color: ${theme.color.accent.primary};
  width: 2rem;
  height: 2rem;
  display: inline-block;
  margin-right: 1.7rem;
`
