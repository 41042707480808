import React, { FC, SVGProps } from 'react'
import Svg from 'components/svg'

const ApplePay: FC<SVGProps<SVGSVGElement>> = props => (
  <Svg {...props} viewbox="0 0 50 21">
    <path
      d="M9.34666 2.7053C9.9289 1.97705 10.324 0.999222 10.2198 0C9.36748 0.0423806 8.3274 0.562299 7.72524 1.29112C7.18457 1.91524 6.70603 2.93401 6.83078 3.89135C7.78755 3.97434 8.74343 3.41312 9.34666 2.7053Z"
      fill="#172126"
    />
    <path
      d="M10.2088 4.07818C8.81937 3.99541 7.638 4.86676 6.97447 4.86676C6.31057 4.86676 5.29448 4.11988 4.1955 4.14001C2.7651 4.16102 1.43786 4.96978 0.711999 6.25608C-0.78098 8.82932 0.318002 12.6463 1.76985 14.7421C2.47489 15.7789 3.3246 16.9206 4.44421 16.8795C5.50206 16.838 5.91669 16.1946 7.20255 16.1946C8.48746 16.1946 8.86096 16.8795 9.98075 16.8588C11.142 16.838 11.868 15.8214 12.573 14.7836C13.3819 13.6017 13.713 12.4604 13.7338 12.3977C13.713 12.377 11.4946 11.526 11.474 8.97411C11.453 6.83741 13.2157 5.82107 13.2986 5.75805C12.3033 4.28588 10.748 4.11988 10.2088 4.07818Z"
      fill="#172126"
    />
    <path
      d="M22.3069 1.18677C25.3268 1.18677 27.4297 3.26845 27.4297 6.29922C27.4297 9.3408 25.2835 11.4333 22.2311 11.4333H18.8875V16.7507H16.4717V1.18677H22.3069V1.18677ZM18.8875 9.4055H21.6594C23.7627 9.4055 24.9598 8.27312 24.9598 6.31003C24.9598 4.34715 23.7627 3.22538 21.6702 3.22538H18.8875V9.4055Z"
      fill="#172126"
    />
    <path
      d="M28.0615 13.5261C28.0615 11.5413 29.5823 10.3226 32.279 10.1716L35.385 9.98828V9.11471C35.385 7.85272 34.5329 7.09773 33.1095 7.09773C31.7609 7.09773 30.9196 7.74475 30.7149 8.75875H28.5146C28.644 6.70932 30.3912 5.19934 33.1956 5.19934C35.9459 5.19934 37.7039 6.65543 37.7039 8.93122V16.7509H35.4712V14.885H35.4175C34.7597 16.147 33.325 16.945 31.8367 16.945C29.6148 16.945 28.0615 15.5645 28.0615 13.5261ZM35.385 12.5014V11.6062L32.5914 11.7787C31.2001 11.8759 30.4128 12.4906 30.4128 13.4613C30.4128 14.4535 31.2325 15.1007 32.4837 15.1007C34.1122 15.1007 35.385 13.979 35.385 12.5014Z"
      fill="#172126"
    />
    <path
      d="M39.8117 20.9251V19.0376C39.984 19.0806 40.3722 19.0806 40.5665 19.0806C41.645 19.0806 42.2275 18.6277 42.5833 17.4629C42.5833 17.4412 42.7884 16.7726 42.7884 16.7618L38.6899 5.4043H41.2135L44.0828 14.637H44.1257L46.995 5.4043H49.4541L45.2042 17.3441C44.2339 20.0946 43.1121 20.979 40.7608 20.979C40.5665 20.979 39.984 20.9574 39.8117 20.9251Z"
      fill="#172126"
    />
  </Svg>
)

export default ApplePay
